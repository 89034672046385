const VoteButton = ({ place, onVote, isSelected }) => {
    return (
        <button
        onClick={() => onVote(place)}
        style={{
          flex: 1,
          margin: '0.1rem',
          padding: '0.5rem',
          backgroundColor: isSelected ? '#333' : '#1bc300',
          color: '#fff',
          fontSize: '1rem',
          fontWeight: 'bold',
          borderRadius: '5px',
          border: '1px solid black',
          cursor: 'pointer',
          '&:hover': {
            filter: 'brightness(1.2)',
            backgroundColor: '#333', // Optional: Adjust hover color here if needed
          },
        }}
      >
        {place} Place
      </button>
    );
  };

export default VoteButton;