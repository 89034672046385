import React from 'react';
import DownloadButton from './DownloadButton';

const GalleryPhotoCard = ({ playerEntryNumber, artistName, imageUrl, printLayoutURL, imgUrl, promptText, playerId }) => {
  return (
    <div className="photo-card">
      <div className="photo-card-header">
        <div className="artist-name">{artistName}</div>
        <div className="player-entry-number">{playerEntryNumber}</div>
      </div>
      <img loading="lazy" src={imageUrl} alt={`Artwork by ${artistName}`} className="photo-card-image" />
      <div className="prompt-text" style={{ paddingTop: '3px', paddingBottom: '5px' }}>{promptText}</div>
      <div className="download-buttons">
        <DownloadButton playerId={playerId} linkText="Raw Image" photoLink={imgUrl} downloadLink={imgUrl} isSelected={false} />
        <DownloadButton playerId={playerId} linkText="Print Layout" photoLink={printLayoutURL} downloadLink={printLayoutURL} isSelected={false} />
      </div>
    </div>
  );
};

export default GalleryPhotoCard;