import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faDownload } from '@fortawesome/free-solid-svg-icons'

const DownloadButton = ({ playerId, linkText, photoLink, downloadLink, isSelected }) => {
    // Function to handle the download action
    const handleDownload = () => {
        // Create an <a> element
        const link = document.createElement('a');
        link.href = downloadLink;
        link.download = ''; // This property can be set to specify the filename, or left empty to use the filename from the downloadLink

        // Append to the document and trigger click
        document.body.appendChild(link);
        link.click();

        // Clean up by removing the link from the document
        document.body.removeChild(link);
    };

    return (
        <button
            onClick={handleDownload} // Use handleDownload function for the onClick event
            style={{
                flex: 1,
                margin: '0.1rem',
                padding: '0.5rem',
                backgroundColor: isSelected ? '#333' : '#1bc300',
                color: '#fff',
                fontSize: '1rem',
                fontWeight: 'bold',
                borderRadius: '5px',
                border: '1px solid black',
                cursor: 'pointer',
                // Note: '&:hover' won't work here as this is not a CSS-in-JS solution like styled-components or emotion
                // You'll need to use a different approach for hover styles, such as CSS classes or inline styles with onMouseEnter/onMouseLeave events
            }}
        >
            {linkText} <FontAwesomeIcon icon={faDownload} />
        </button>
    );
};

export default DownloadButton;