import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import WinnerPhotoCard from './components/WinnerPhotoCard';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faSquareArrowUpRight } from '@fortawesome/free-solid-svg-icons'

const ResultsPage = () => {
  const { gameId } = useParams();
  const [winners, setWinners] = useState([]);
  const [winnersAnnounced, setWinnersAnnounced] = useState(false); // Add this line
  const galleryLink = `/gallery/${gameId}`;

  useEffect(() => {
    document.body.style.backgroundColor = "#bff2c9";
    const fetchWinners = async () => {
      try {
        const response = await fetch(`/api/results/${gameId}`);
        // const response = await fetch(`http://localhost:4000/api/results/${gameId}`); 
        // console.log(gameId);
        if (!response.ok) {
          throw new Error('Failed to fetch winners');
        }
        const data = await response.json();
        // console.log(data);

        if (data.length === 0) { // Check if the winners array is empty
          // console.log('No winners announced yet');

        } else {
          setWinners(data); // Update state with the winners data
          setWinnersAnnounced(true); // This correctly sets the state now
        }
      } catch (error) {
        console.error('Error fetching winners:', error);
      }
    };
    fetchWinners();
    return () => {
      document.body.style.backgroundColor = ""; // Clear the background color on unmount
    };
  }, [gameId]);

  if (!winnersAnnounced) {
    return (
      <div className="app-container" style={{height: '100vh'}}>
        <div style={{ padding: '20px 20px 0 20px', textAlign: 'center' }}>
          <img src={`${process.env.PUBLIC_URL}/images/logo.png`} alt="Logo" style={{ height: '100px', paddingLeft:'5px' }} />
          <h1 style={{ margin:'3px', color: '#fff'}}>The winners will be announced soon.</h1>
        </div>
        <div style={{ display: 'flex', justifyContent: 'center', fontSize: '0.75rem', margin: '10px 20px 0 20px', padding: '3px', backgroundColor: '#333333', borderRadius: '20px' }}>
      <a href={galleryLink} style={{ textDecoration: 'bold' }}>
        <button style={{ margin: '3px', padding: '6px 10px 6px 10px', borderRadius: '15px', color: '#000', fontWeight: 'bold', backgroundColor: '#bff2c9' }}>Your Bad Art Gallery <FontAwesomeIcon icon={faSquareArrowUpRight} /> </button>
      </a>
    </div>
      </div>
    );
  }

  return (
    <div className="app-container">
      <div style={{ padding: '20px 20px 0 20px', textAlign: 'center' }}>
        <img src={`${process.env.PUBLIC_URL}/images/logo.png`} alt="Logo" style={{ height: '100px', paddingLeft:'5px' }} />
        <div style={{ fontSize: '0.75rem', margin: '0 20px 0 20px', padding: '3px', backgroundColor: '#e9fbed', borderRadius: '20px' }}>
          <h1 style={{ margin:'3px', color: '#000'}}>Congrats Winners!</h1>
        </div>
        <div style={{ display: 'flex', justifyContent: 'center', fontSize: '0.75rem', margin: '10px 20px 0 20px', padding: '3px', backgroundColor: '#333333', borderRadius: '20px' }}>
      <a href={galleryLink} style={{ textDecoration: 'bold' }}>
        <button style={{ margin: '3px', padding: '6px 10px 6px 10px', borderRadius: '15px', color: '#000', fontWeight: 'bold', backgroundColor: '#bff2c9' }}>Your Bad Art Gallery</button>
      </a>
    </div>
        <div>
          {winners.map((winner, index) => (
            <WinnerPhotoCard
              key={winner.playerId}
              artistName={winner.artistName}
              imageUrl={winner.transformedWinnerUrl}
              rank={index + 1} // Assuming the rank should be the index + 1
            />
          ))}
        </div>
      </div>
    </div>
  );
};

export default ResultsPage;
