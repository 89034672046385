import React, { useState, useEffect } from 'react';
// import mockData from './mockData';
import MobileHeader from './components/MobileHeader';
import GalleryPhotoCard from './components/GalleryPhotoCard';
import { useParams } from 'react-router-dom';
import './GalleryPage.css';

const GalleryPage = () => {
  const [photos, setPhotos] = useState([]); // Start with an empty array
  const { gameId } = useParams(); // Assuming you're using React Router and gameId is a URL parameter
  const [columns, setColumns] = useState(4);

  useEffect(() => {
    document.body.style.backgroundColor = "#bff2c9";
    
    // Fetch game data from backend
    const fetchGameDetails = async () => {
      try {
        const response = await fetch(`/api/game/${gameId}`);
        // const response = await fetch(`http://localhost:4000/api/game/${gameId}`); // This is for local testing 
        if (!response.ok) {
          throw new Error('Game data fetch failed');
        }
        const gameDetails = await response.json();
        setPhotos(gameDetails.players); 
      } catch (error) {
        console.error('Error fetching game details:', error);
      }
    };

    fetchGameDetails();
    
    return () => {
      document.body.style.backgroundColor = ""; // Clear the background color on unmount
    };
  }, [gameId]); // Re-fetch if gameId changes

  useEffect(() => {
    const updateColumns = () => {
      const screenWidth = window.innerWidth;
      if (screenWidth >= 1200) {
        setColumns(4);
      } else if (screenWidth >= 992) {
        setColumns(3);
      } else if (screenWidth >= 768) {
        setColumns(2);
      } else {
        setColumns(1);
      }
    };

    updateColumns();
    window.addEventListener('resize', updateColumns);
    return () => {
      window.removeEventListener('resize', updateColumns);
    };
  }, []);


  return (
    <div className="App">
         <MobileHeader />
       <div className="gallery-grid">
      <div className="content" style={{ marginTop: '82px' }}> 
      <div className={`gallery-grid columns-${columns}`}>
      {photos.map((photo) => (
        <GalleryPhotoCard
          key={photo.playerId} // Use playerId as the unique key
          playerEntryNumber={photo.playerEntryNumber}
          artistName={photo.artistName}
          imageUrl={photo.transformedUrl}
          playerId={photo.playerId} // Pass playerId to PhotoCard
          promptText={photo.promptText}
          imgUrl={photo.imgUrl}
          printLayoutURL={photo.printLayoutURL}
        />
      ))}
      </div>
      </div>
    </div>
    </div>
  );
}


export default GalleryPage;
