import React, { useEffect } from 'react';
import { BrowserRouter as Router, Route, Routes} from 'react-router-dom'; 
import VotingPage from './VotingPage';
import ResultsPage from './ResultsPage';
import GalleryPage from './GalleryPage';

  function App() {
    useEffect(() => {
      if (window.location.pathname === '/') {
        window.location.href = 'https://musepop.io';
      }
    }, []);
  return (
    <Router>
      <Routes> 
        <Route path="/vote/:gameId" element={<VotingPage />} /> {/* Updated path */}
        <Route path="/results/:gameId" element={<ResultsPage />} /> {/* Updated path to include gameId */}
        <Route path="/gallery/:gameId" element={<GalleryPage />} /> {/* Updated path to include gameId */}
        <Route path="*" element={<div>404 - Page Not Found</div>} />
      </Routes>
    </Router>
  );
}

export default App;