import React from 'react';

const WinnerPhotoCard = ({ artistName, imageUrl, rank }) => {
    const getPlaceDetails = (rank) => {
        switch(rank) {
            case 1:
                return { place: '1st Place', icon: '🏆' };
            case 2:
                return { place: '2nd Place', icon: '🥈' };
            case 3:
                return { place: '3rd Place', icon: '🥉' };
            default:
                return { place: `${rank}th Place`, icon: '' }; // Just in case
        }
    };

    const { place, icon } = getPlaceDetails(rank);

    return (
      <div style={{
        // backgroundColor: '#bff2c9',
        backgroundColor: '#e9fbed',
        margin: '20px',
        padding: '20px',
        borderRadius: '16px',
        filter: 'drop-shadow(0 4px 4px rgba(0,0,0,0.25))',
        position: 'relative', // Needed to position the icon absolutely within the card
      }}>
         <div style={{
          fontWeight: 'bold',
          marginBottom: '5px',
          textAlign: 'center', // Center the artist's name
        }}>{place}</div>
        <img src={imageUrl} alt={`Artwork by ${artistName}`} style={{ width: '100%', borderRadius: '5px', filter: 'drop-shadow(0 4px 4px rgba(0,0,0,0.25))',  }} />
        <div style={{
          fontWeight: 'bold',
          paddingTop: '3px',
          marginBottom: '35px',
          textAlign: 'center', // Center the artist's name
        }}>{artistName}</div>
        {/* Positioning the icon at the bottom of the card */}
        <div style={{
          position: 'absolute',
          bottom: '10px', // Adjust as needed to position below the artist's name
          left: '50%', // Start by placing at the exact horizontal center
          transform: 'translateX(-50%)', // Shift it back by half its width to truly center
          color: '#fff',
          fontSize: '1.5rem',
          backgroundColor: '#ff09ab',
          borderRadius: '50%',
          padding: '5px',
          height: '30px',
          width: '30px',
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
        }}>
          {icon}
        </div>
      </div>
    );
};

export default WinnerPhotoCard;