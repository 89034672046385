import React from 'react';

const Header = ({ timeLeft, windowWidth }) => {
    // Convert timeLeft to minutes and seconds for display
    const minutes = Math.floor(timeLeft / 60);
    const seconds = Math.floor(timeLeft % 60);
  return (
    <header style={{
      position: 'fixed',
      top: 0,
      zIndex: 1000,
      width: '97.8%',
      maxWidth: '430px', 
      height: '65px',
      backgroundColor: '#bff2c9',
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'space-between',
      filter: 'drop-shadow(0 4px 4px rgba(0,0,0,0.25))',
      paddingLeft: '5px',
      border: 'black 2px solid',
    }}>
      <img
        src={`${process.env.PUBLIC_URL}/images/logo.png`}
        alt="Logo"
        style={{ height: '50px', paddingLeft:'5px' }}
      />
      {windowWidth >= 350 && (
         <img
         src={`${process.env.PUBLIC_URL}/images/MusepopLogo.png`}
         alt="Logo"
         className="header-logo"
         style={{ height: '35px' }}
       />
      )}
      <div style={{ textAlign: 'right', paddingRight: '15px' }}>
        <div style={{ fontSize: '0.65rem' }}>Place Your Votes</div>
        <div style={{ fontSize: '2rem' }}>
        {`${minutes}:${seconds < 10 ? '0' : ''}${seconds}`}
        </div>
      </div>
    </header>
  );
};

export default Header;