import React, { useState, useEffect } from 'react';

const MobileHeader = () => {
  const [windowWidth, setWindowWidth] = useState(window.innerWidth);

  useEffect(() => {
    const handleResize = () => setWindowWidth(window.innerWidth);
    window.addEventListener('resize', handleResize);
    return () => window.removeEventListener('resize', handleResize);
  }, []);

  // Inline style for the header
  const headerStyle = {
    position: 'fixed',
    top: 0,
    zIndex: 1000,
    width: '100%', // Use full width
    maxWidth: '100%', // Allow up to 100% of the viewport width
    height: '65px',
    backgroundColor: '#bff2c9',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    filter: 'drop-shadow(0 4px 4px rgba(0,0,0,0.25))',
    paddingLeft: windowWidth > 768 ? '20px' : '5px', // Adjust padding based on screen width
    paddingRight: windowWidth > 768 ? '20px' : '5px', // Same for right padding
    border: 'black 2px solid',
    boxSizing: 'border-box', // Ensure padding is included in width
  };

  return (
    <>
      <style>
        {`
          @media (min-width: 768px) {
            /* Styles for screens wider than 768px */
            .header-logo {
              margin-right: 10px; /* More space around the logo on larger screens */
            }
          }
        `}
      </style>
      <header style={headerStyle}>
      <a href="https://musepop.io" target="_blank" rel="noreferrer">
        <img
          src={`${process.env.PUBLIC_URL}/images/logo.png`}
          alt="Logo"
          height="50px"
          style={{ marginTop: '3px', height: '50px', paddingLeft: windowWidth > 768 ? '10px' : '5px' }}
        />
        </a>
        {windowWidth >= 350 && (
          <a href="https://musepop.io" target="_blank" rel="noreferrer">
            <img
              src={`${process.env.PUBLIC_URL}/images/MusepopLogo.png`}
              alt="Logo"
              className="header-logo"
              height="35px"
              style={{ height: '35px' }} // CSS media query takes care of margin adjustments
            />
          </a>
        )}
      </header>
    </>
  );
};

export default MobileHeader;
