import React from 'react';
import VoteButton from './VoteButton'; // Import the VoteButton component

const PhotoCard = ({ playerEntryNumber, artistName, imageUrl, votes, onVote, playerId }) => {
    // Removed the unused code that was causing errors

    return (
      <div style={{ backgroundColor: '#fff', margin:'20px', padding:'20px', borderRadius: '16px', filter: 'drop-shadow(0 4px 4px rgba(0,0,0,0.25))' }}>
        <div style={{ position: 'relative' }}>
          <div style={{paddingBottom: '3px', fontWeight: 'bold'}}>{artistName}</div>
          <div style={{
            position: 'absolute',
            top: 0,
            right: '10px',
            color: '#fff',
            fontSize: '1.5rem',
            backgroundColor: '#ff09ab',
            borderRadius: '50%',
            padding: '5px',
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            height: '30px',
            width: '30px',
            }}>
            {playerEntryNumber}
          </div>
        </div>
        <img src={imageUrl} alt={`Artwork by ${artistName}`} style={{ width: '100%', borderRadius: '5px' }} />
        <div style={{fontWeight: 'bold'}}>Select Winners:</div>
        <div style={{
          display: 'flex',
          padding: '0', // Reset any padding
          marginTop: '0.15rem'
        }}>
          <VoteButton
            place="1st"
            onVote={() => onVote(playerId, 'first')}
            isSelected={votes['first'] === playerId}
          />
          <VoteButton
            place="2nd"
            onVote={() => onVote(playerId, 'second')}
            isSelected={votes['second'] === playerId}
          />
          <VoteButton
            place="3rd"
            onVote={() => onVote(playerId, 'third')}
            isSelected={votes['third'] === playerId}
          />
        </div>
      </div>
    );
};

export default PhotoCard;
