import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faSpinner } from '@fortawesome/free-solid-svg-icons';

const SelectedImagesFooter = ({ votes, photos, onVoteSubmission, isVoteReady, isSubmitting }) => {
    const firstPlacePhoto = photos.find(photo => photo.playerId === votes.first);
    const secondPlacePhoto = photos.find(photo => photo.playerId === votes.second);
    const thirdPlacePhoto = photos.find(photo => photo.playerId === votes.third);

    console.log(votes);
    
    return (
        <div style={{
          position: 'fixed', 
          bottom: 0, 
          zIndex: 1000, 
          width: '99%', 
          maxWidth: '430px', // Adjusted to '100%' to allow for responsiveness
          height: 'auto', // Adjusted to 'auto' to allow for padding
          backgroundColor: '#bff2c9', 
          textAlign: 'center', 
          border: 'black 2px solid',
          display: 'flex', 
          flexDirection: 'column',
          alignItems: 'center',
          paddingBottom: isVoteReady ? '1px' : '8px',// Added padding at the bottom
        }}>
          {/* <h3 style={{ paddingTop: '1px', margin: '0.25rem', fontSize: '1rem'}}>Votes</h3>
          <hr style={{ width: '100%', margin: '0.25rem' }} /> */}
          <div style={{ display: 'flex', width: '100%', justifyContent: 'center', alignItems: 'center' }}>
            {['🏆 First', '🏅 Second', '🎨 Third'].map((label, index) => (
              <div key={index} style={{ flex: 1, display: 'flex', flexDirection: 'column', alignItems: 'center', padding: '0 10px' }}> {/* Adjusted padding for child elements */}
                <div style={{paddingBottom: '3px', paddingTop: '2px', fontSize: '0.75em', fontWeight:'bold'}} >{label}</div>
                <img src={index === 0 ? (firstPlacePhoto ? firstPlacePhoto.transformedUrl : `${process.env.PUBLIC_URL}/images/1.jpg`) : index === 1 ? (secondPlacePhoto ? secondPlacePhoto.transformedUrl : `${process.env.PUBLIC_URL}/images/2.jpg`) : (thirdPlacePhoto ? thirdPlacePhoto.transformedUrl : `${process.env.PUBLIC_URL}/images/3.jpg`)} alt={`${label} Place`} style={{ width: '100%', height: 'auto', maxWidth: '100%', maxHeight: 'auto', borderRadius: '8px', border: 'black 2px solid' }} />
              </div>
            ))}
          </div>
          {isVoteReady && (
                <button
                    onClick={onVoteSubmission}
                    disabled={isSubmitting}
                    style={{
                        width: '96%',
                        padding: '8px',
                        margin: '8px',
                        borderRadius: '5px',
                        border: '1px solid black',
                        backgroundColor: isSubmitting ? '#333' : '#1bc300', // Change color when submitting
                        color: 'white',
                        fontSize: '1em',
                        fontWeight: 'bold',
                        cursor: 'pointer',
                    }}
                    >
                    {isSubmitting ? (
                        <span>
                        Submitting <FontAwesomeIcon icon={faSpinner} spin />
                        </span>
                    ) : (
                        'Submit Votes'
                    )}
                    </button>
            )}
        </div>
      );
    };
  
  export default SelectedImagesFooter;