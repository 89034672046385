import React, { useState, useEffect } from 'react';
// import mockData from './mockData';
import PhotoCard from './components/PhotoCard';
import Header from './components/Header';
import SelectedImagesFooter from './components/SelectedImagesFooter';
import { useNavigate, useParams } from 'react-router-dom';

const VotingPage = () => {
  const [votes, setVotes] = useState({ first: null, second: null, third: null });
  const [photos, setPhotos] = useState([]); // Start with an empty array
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [timeLeft, setTimeLeft] = useState(null); // Time left for voting
  const navigate = useNavigate();
  const { gameId } = useParams(); // Assuming you're using React Router and gameId is a URL parameter

  useEffect(() => {
    document.body.style.backgroundColor = "#bff2c9";
    
    // Fetch game data from backend
    const fetchGameDetails = async () => {
      try {
        // const response = await fetch(`/api/game/${gameId}`); // change this to the full URL if the proxy is not set up or for deployment
        const response = await fetch(`/api/game/${gameId}`);
        // const response = await fetch(`http://localhost:4000/api/game/${gameId}`);
        // Given that your backend runs on port 4000, but your frontend on port 3000, you'll need to specify the full URL path in your fetch call if the proxy is not set up in your package.json:
        if (!response.ok) {
          throw new Error('Game data fetch failed');
        }
        const gameDetails = await response.json();
        // Assuming gameDetails contains votingEndTime in a suitable format
        const now = new Date().getTime();
        const votingEndTime = new Date(gameDetails.votingEndTime).getTime();
        const timeLeftInSeconds = Math.max(0, (votingEndTime - now) / 1000); // Calculate difference in seconds, ensure it's not negative
        setTimeLeft(timeLeftInSeconds);
        setPhotos(gameDetails.players); // Assuming the API response contains an array of players in 'gameDetails.players'
      } catch (error) {
        console.error('Error fetching game details:', error);
      }
    };

    fetchGameDetails();
    
    return () => {
      document.body.style.backgroundColor = ""; // Clear the background color on unmount
    };
  }, [gameId]); // Re-fetch if gameId changes


  const handleVote = (playerId, place) => {
    // console.log('Previous Votes:', votes); // Log the state before the update
    setVotes(prevVotes => {
      // Check if the user is deselecting the current vote
      if (prevVotes[place] === playerId) {
        const newVotes = { ...prevVotes, [place]: null };
        // console.log('Updated Votes (deselecting):', newVotes); // Log the update for deselecting
        return newVotes;
      }
      // Check if the entry is already voted for in any place (to prevent voting the same entry in multiple places)
      const alreadyVoted = Object.entries(prevVotes).find(([key, value]) => value === playerId);
      if (alreadyVoted) {
        // Remove the entry from the previous place and set it to the new one
        const [previousPlace] = alreadyVoted;
        const newVotes = { ...prevVotes, [previousPlace]: null, [place]: playerId };
        // console.log('Updated Votes (switching):', newVotes); // Log the update for switching votes
        return newVotes;
      }
      // If not deselecting or switching, simply set the new vote
      const newVotes = { ...prevVotes, [place]: playerId };
      // console.log('Updated Votes (new vote):', newVotes); // Log the update for a new vote
      return newVotes;
    });
  };

  const submitVotes = async () => {
    // Check if all votes are cast
    if (isReadyToSubmit(votes)) {
      setIsSubmitting(true); // Start of action
      try {
        // Prepare the data for submission
        const voteEntries = [
          { playerId: votes.first, place: "firstPlace", points: 3 },
          { playerId: votes.second, place: "secondPlace", points: 2 },
          { playerId: votes.third, place: "thirdPlace", points: 1 },
        ];
        const voteSubmission = {
          gameId,
          votes: voteEntries, // Updated to use the transformed voteEntries
        };
  
        // console.log('Submitting votes:', voteSubmission);
  

        // Send the data to your backend
        const response = await fetch('/api/vote', {
        // const response = await fetch('http://localhost:4000/api/vote', {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
          },
          body: JSON.stringify(voteSubmission),
        });

        const result = await response.json();
        if (response.ok) {
          // Handle successful submission, such as resetting the vote state
          // or displaying a success message to the user.
          // console.log('Votes submitted successfully:', voteSubmission);
          setVotes({ first: null, second: null, third: null });
          navigate(`/results/${gameId}`); // Navigate to the results page
        } else {
          // Handle any errors returned from the server
          console.error(result.message);
        }
      } catch (error) {
        // Handle errors from the fetch itself
        console.error('Error submitting votes:', error);
      }
    } else {
      // Handle the case where not all votes are cast
      console.error('Please cast all your votes before submitting.');
    }
  };


  const isReadyToSubmit = (votes) => {
    return votes.first !== null && votes.second !== null && votes.third !== null;
  };

  const hasVotes = votes.first !== null || votes.second !== null || votes.third !== null;


  const windowWidth = window.innerWidth; // You could also manage this with a useState and useEffect if dynamic window width is needed

  useEffect(() => {
    // Ensure timer only runs if timeLeft is greater than 0 this also ensures the redirect to winners if the time is up!
    if (timeLeft > 0) {
        const timer = setTimeout(() => {
          setTimeLeft(prevTimeLeft => Math.max(0, prevTimeLeft - 1));
      }, 1000);
        // Cleanup timeout on component unmount or before the next effect runs
        return () => clearTimeout(timer);
    } else if (timeLeft === 0) {
        // console.log("Redirecting to results page..."); // For debugging
        navigate(`/results/${gameId}`);
    }
}, [timeLeft, gameId, navigate]);

  return (
    <div className="App">
       <div className="app-container">
       <Header timeLeft={timeLeft} windowWidth={windowWidth}  />
      {/* Your existing page content */}
      <div className="content" style={{ marginTop: '82px' }}> 
      <div className="content">
      {photos.map((photo) => (
        <PhotoCard
          key={photo.playerId} // Use playerId as the unique key
          playerEntryNumber={photo.playerEntryNumber}
          artistName={photo.artistName}
          imageUrl={photo.transformedUrl}
          votes={votes}
          onVote={handleVote}
          playerId={photo.playerId} // Pass playerId to PhotoCard
        />
      ))}
      </div>
      </div>
      {hasVotes && (
      <div style={{ marginBottom: '178px' }}>
        <SelectedImagesFooter 
          votes={votes} 
          photos={photos} 
          onVoteSubmission={submitVotes} 
          isVoteReady={isReadyToSubmit(votes)} 
          isSubmitting={isSubmitting}
      />
      </div>
    )}
    </div>
    </div>
  );
}


export default VotingPage;
